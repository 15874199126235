import { useState } from 'react';

import { IconEmojiRenderer } from '@assured/design-system/src/components/Icon/IconEmojiRenderer';
import { IconFlatRenderer } from '@assured/design-system/src/components/Icon/IconFlatRenderer';
import { useStep } from '@assured/step-renderer/components';
import {
  PartyEntryButtonValue,
  PartyEntryDataEntry,
  PartyEntryListButtons,
} from '@assured/step-renderer/types/step-components/PartyEntry';
import {
  WitnessEntryButtonValue,
  WitnessEntryDataEntry,
} from '@assured/step-renderer/types/step-components/WitnessEntry';
import { capitalize } from '@assured/utilities/src/capitalize';

import Modal from '../../Modal';
import { PartyEntryEditableListItem } from './PartyEntryEditableListItem';

export const PartyEntryEditableList = ({
  parties,
  buttons,
  updateValue,
  field,
  inlineEdit = true,
}: {
  parties: Partial<PartyEntryDataEntry | WitnessEntryDataEntry>[];
  buttons: PartyEntryListButtons;
  updateValue: (
    field: string | undefined,
    value: PartyEntryButtonValue | WitnessEntryButtonValue,
  ) => void;
  field?: string;
  inlineEdit?: boolean;
}) => {
  const [viewModal, setViewModal] = useState<{
    show: boolean;
    party?: Partial<PartyEntryDataEntry>;
  }>({ show: false });
  const [editingId, setEditingId] = useState<string | null>(null);
  const { step } = useStep();

  const onEdit = (party: Partial<PartyEntryDataEntry>) => {
    if (!party.id) return;
    // If the party is a pedestrian or bicycle, we can edit the name in the next step
    // We do not need to display the in-line edit form
    if (party.partyType === 'PEDESTRIAN' || party.partyType === 'BICYCLE') {
      if (!party.editPartyActions?.editName) return;
      updateValue(field, party.editPartyActions.editName);
    } else {
      setEditingId(party.id);
    }
  };

  // We need to provide which edit action we want to perform
  const onNavigateToVehicleMakeEditPage = (
    party: Partial<PartyEntryDataEntry>,
  ) => {
    if (!party.id) return;
    if (!party.editPartyActions?.editVehicleMake) return;
    if (party.editPartyActions.editVehicleMake) {
      updateValue(field, party.editPartyActions.editVehicleMake);
    }
  };

  const onNavigateToVehicleColorEditPage = (
    party: Partial<PartyEntryDataEntry>,
  ) => {
    if (!party.id) return;
    if (!party.editPartyActions?.editVehicleColor) return;
    if (party.editPartyActions.editVehicleColor) {
      updateValue(field, party.editPartyActions.editVehicleColor);
    }
  };

  const onNavigateToCommercialAutoVehicleTypeEditPage = (
    party: Partial<PartyEntryDataEntry>,
  ) => {
    if (!party.id) return;
    if (!party.editPartyActions?.editCommercialAutoVehicleType) return;
    if (party.editPartyActions.editCommercialAutoVehicleType) {
      updateValue(field, party.editPartyActions.editCommercialAutoVehicleType);
    }
  };

  const onCancelEdit = (id?: string) => {
    if (!id) return;
    if (editingId === id) {
      setEditingId(null);
    }
  };

  const onRemoveParty = (party: Partial<PartyEntryDataEntry>) => {
    if (!party.id) return;
    setViewModal({ show: true, party });
  };

  const onModalClose = () => {
    setViewModal({ show: false });
  };

  const enableCommercialAutoVehicleTypeEdit =
    !!step.content.step_components.find(c => c.type === 'party_entry')
      ?.enableCommercialAutoFields;

  const items = parties.map(party => {
    return (
      <PartyEntryEditableListItem
        icon={<PartyIcon party={party} />}
        key={party.id}
        title={getTitle(party)}
        vehicle={isPartyEntryDataEntry(party) ? party.vehicle : undefined}
        isEditing={editingId === party.id}
        description={getDescription(party)}
        onRemoveParty={
          'blockRemovingParty' in party && party.blockRemovingParty
            ? undefined
            : () => onRemoveParty(party)
        }
        onEdit={
          'blockEditingParty' in party && party.blockEditingParty
            ? undefined
            : () => {
                if (isWitnessDataEntry(party)) {
                  updateValue(field, party?.editWitnessAction);
                } else {
                  onEdit(party);
                }
              }
        }
        onNavigateToCommercialAutoVehicleTypeEditPage={
          enableCommercialAutoVehicleTypeEdit
            ? () =>
                isPartyEntryDataEntry(party) &&
                onNavigateToCommercialAutoVehicleTypeEditPage(party)
            : undefined
        }
        onNavigateToVehicleMakeEditPage={() =>
          isPartyEntryDataEntry(party) && onNavigateToVehicleMakeEditPage(party)
        }
        onNavigateToVehicleColorEditPage={() =>
          isPartyEntryDataEntry(party) &&
          onNavigateToVehicleColorEditPage(party)
        }
        onCancelEdit={() => onCancelEdit(party.id)}
        inlineEdit={inlineEdit}
      />
    );
  });

  return (
    <div>
      {items}
      <button
        type="button"
        onClick={() => {
          if (buttons.primaryButton.value) {
            updateValue(field, buttons.primaryButton.value);
          }
        }}
        className="btn btn-subtle w-full m-0 mt-2"
      >
        <span className="text-lg text-center font-normal pr-2">+</span>
        <span className="text-base text-center font-normal">
          {buttons.primaryButton.label
            ? buttons.primaryButton.label
            : 'Add another party'}
        </span>
      </button>
      {viewModal.show && (
        <RemovePartyModal
          party={viewModal?.party}
          updateValue={updateValue}
          field={field}
          onClose={onModalClose}
        />
      )}
    </div>
  );
};

const PartyIcon = ({ party }: { party: Partial<PartyEntryDataEntry> }) => {
  if (party.partyType === 'WITNESS') {
    return <div className="h-[20px] w-[20px]">👀</div>;
  }

  if (party.partyType === 'PEDESTRIAN') {
    return <div className="h-[20px] w-[20px]">🚶</div>;
  }

  if (party.partyType === 'BICYCLE') {
    return <div className="h-[20px] w-[20px]">🚲</div>;
  }

  if (!party.vehicle?.color || party.vehicle.color === 'OTHER') {
    return (
      <IconEmojiRenderer width={20} height={20} iconKey="ICON_EMOJI_OTHER" />
    );
  }

  return (
    <div
      className="h-[20px] w-[20px] rounded-md border"
      style={{ backgroundColor: party.vehicle.color }}
    />
  );
};

const getTitle = (party: Partial<PartyEntryDataEntry>): string => {
  if (party.partyType === 'WITNESS') {
    return party?.name || 'Unknown';
  }

  if (party.partyType === 'PEDESTRIAN') {
    return 'Pedestrian';
  }

  if (party.partyType === 'BICYCLE') {
    return 'Bicyclist';
  }

  if (party.vehicle?.commercialAutoVehicleType) {
    return party.vehicle.commercialAutoVehicleType;
  }

  return 'Vehicle';
};

const getDescription = (party: Partial<PartyEntryDataEntry>): string | null => {
  if (party.partyType === 'WITNESS') {
    return null;
  }

  if (party.partyType === 'PEDESTRIAN' || party.partyType === 'BICYCLE') {
    return party.name || 'Unknown name';
  }

  const makeAndOrType = party.vehicle?.make;

  if (!party.vehicle?.color && !makeAndOrType) {
    return 'Unknown vehicle';
  }

  if (!makeAndOrType && party.vehicle?.color) {
    return `${capitalize(party.vehicle.colorLabel || '')} vehicle`;
  }

  if (!party.vehicle?.color && makeAndOrType) {
    return `${makeAndOrType}`;
  }

  return [capitalize(party.vehicle?.colorLabel || ''), makeAndOrType]
    .filter(Boolean)
    .join(' ');
};

const RemovePartyModal = ({
  party,
  updateValue,
  field,
  onClose,
}: {
  party: Partial<PartyEntryDataEntry> | undefined;
  updateValue: (
    field: string | undefined,
    value: PartyEntryButtonValue,
  ) => void;
  field?: string;
  onClose: () => void;
}) => {
  if (!party) return null;

  const actions = [
    {
      title: 'Yes, remove',
      primary: true,
      className: 'btn-small w-1/2 mb-3',
      onClick: () => {
        if (party.removePartyAction) {
          updateValue(field, party.removePartyAction);
        }
        if (isWitnessDataEntry(party)) {
          updateValue(field, party.removeWitnessAction);
        }
      },
    },
    {
      title: 'Never mind',
      primary: false,
      onClick: onClose,
    },
  ];

  return (
    <Modal
      open
      title={<RemovePartyModalTitle />}
      body={<RemovePartyModalBody party={party} />}
      actions={actions}
    />
  );
};

const RemovePartyModalTitle = () => {
  return (
    <div className="flex items-center justify-center">
      <IconFlatRenderer width={32} height={32} iconKey="ICON_FLAT_TRASH" />
    </div>
  );
};
const RemovePartyModalBody = ({
  party,
}: {
  party: Partial<PartyEntryDataEntry>;
}) => {
  return (
    <div className="mt-6 mb-6">
      <div className=" text-gray-600 text-lg text-center mb-3">
        Are you sure you want to remove the following party?
      </div>
      <div className="flex justify-center pb-6">
        <PartyIcon party={party} />
        <span className="font-bold text-gray-600 text-lg pl-3">
          {party.partyType === 'WITNESS' ? party?.name : getDescription(party)}
        </span>
      </div>
    </div>
  );
};

const isPartyEntryDataEntry = (
  party: Partial<PartyEntryDataEntry | WitnessEntryDataEntry>,
): party is PartyEntryDataEntry => {
  return (
    (party as PartyEntryDataEntry).vehicle !== undefined &&
    party.partyType !== 'WITNESS'
  );
};

const isWitnessDataEntry = (
  party: Partial<PartyEntryDataEntry | WitnessEntryDataEntry>,
): party is WitnessEntryDataEntry => {
  return (
    (party as WitnessEntryDataEntry).partyType === 'WITNESS' &&
    'editWitnessAction' in party
  );
};
